.main{
    background-color: #D80032;
    display: flex;
    align-items: center;
    padding: .4rem 1.4rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    justify-content: space-between;
}

.logo{
    width: 60px;
    
    /* mix-blend-mode: multiply; */
    border-radius: 50%;

}
.heading{
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
}