.mainBody {
    padding-top: calc(60px + .8rem);
}


.headerSection {
    height: 100vh;
    width: 100%;
    position: relative;
}

.headerSection img {
    width: 100%;
    position: absolute;
    height: 100vh;
    object-fit: cover;
    z-index: 10;
}

.headerContent {
    position: absolute;
    z-index: 11;
    /* height: 100%; */
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.749), transparent);
    padding-left: 5rem;
    display: flex;
}

.headerContent span {
    font-weight: 800;
    font-size: 4rem;
    width: 50%;
    /* font-family: poppins; */
    color: white;
    align-self: center;
}

.aboutus {
    display: flex;
    padding: 0 3rem;
    padding-bottom: 2rem;
}

.aboutus .content {
    width: 50%;
    padding: 2rem 4rem;
    justify-content: center;
    align-self: center;
}
.aboutus .dirPic{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.aboutus .dirPic img{
    object-fit: cover;
    width: 100%;
    height: 500px;
    object-position: top;
    
}

h3{
    text-align: center;
    padding-top: 4rem;
    font-size: 3rem;
}

.footer{
    background-color: #D80032;
    color: white;
    padding: 1rem;
}

.gmail{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .8rem;
}
.gmail:hover{
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .headerContent{
        padding-left: 1rem;
        background-image: unset;
    }
    .headerContent span{
        font-size: 3rem;
        font-weight: 600;
        /* align-self:flex-start; */
        text-align: center;
        width: 100%;
    }
    .headerSection img{
        object-fit:cover;
        object-position:70%;
    }
    h3{
        padding-top: 2rem;
    }
    .aboutus{
        flex-direction: column;
        padding: 2rem 2rem;
    }
    .aboutus .content{
        width: 100%;
        padding: 1rem 0;
    }
    .aboutus .dirPic{
        width: 100%;
    }
}